import axios, { AxiosInstance, InternalAxiosRequestConfig, AxiosRequestHeaders, AxiosResponse, AxiosError } from "axios";
import qs from 'qs'
import { config } from "@/axios/config";
import { store } from '../store';
import { ElMessage, ElMessageBox } from "element-plus";
import { logout } from "@/utils/logout";
import { useEnvStore } from '@/store/env';


const { base_url, result_code } = config;

export const PATH_URL = base_url[process.env.NODE_ENV];
export const NODE_ENV = process.env.NODE_ENV;

const envStore = useEnvStore(store);
envStore.INIT_ENV(NODE_ENV);

// 创建axios实例
const service: AxiosInstance = axios.create({
    baseURL: PATH_URL,
    timeout: config.request_timeout
})

service.interceptors.request.use(
    (config: InternalAxiosRequestConfig & any) => {
        if (NODE_ENV === 'development' && envStore.GET_ENV) {
            console.log('asd');
            if (!config.absolutelyUrl) {
                config.url = base_url[envStore.GET_ENV] + config.url;
            } else {
                console.log(config.url);
            }
        }
        if (config.method === 'post' && (config.headers as AxiosRequestHeaders)['Content-Type'] === 'application/x-www-form-urlencoded') {
            config.data = qs.stringify(config.data)
        }
        if (config.method === 'post' && (config.headers as AxiosRequestHeaders)['Content-Type'] === 'application/json') {
            config.data = JSON.stringify(config.data)
        }
        if (config.method === 'get' && config.params) {
            let url = config.url as string
            url += '?'
            const keys = Object.keys(config.params)
            for (const key of keys) {
                if (config.params[key] !== '' && config.params[key] !== void 0 && config.params[key] !== null) {
                    url += `${key}=${encodeURIComponent(config.params[key])}&`
                }
            }
            url = url.substring(0, url.length - 1)
            config.params = {}
            config.url = url
        }
        return config
    },
    (error: AxiosError) => {
        // ElMessage.error(error)
        ElMessageBox({ title: '错误信息', message: error.message })
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    (response: AxiosResponse<any>) => {
        if (response.config.responseType === 'arraybuffer') {
            // 文件流直接过
            return response
        } else if (response.data.statusCode === result_code) {
            return response.data
        } else if (response.data.statusCode === 401) {
            logout(false)
        } else {
            // ElMessage.error(response.data.message)
            response.data.message && ElMessageBox({ title: '错误信息', message: response.data.message })
            return response.data
        }
    },
    (error: AxiosError) => {
        if (error.response && error.response.status === 401) {
            logout(false)
        } else if (error.code === 'ECONNABORTED' || error.message ===   "Network Error" ||  error.message.includes("timeout")) {
            ElMessage.error('请求超时');
        } else {
            ElMessageBox({ title: '错误信息', message: error.message, showClose: true });
        }
        // ElMessage.error(error.message)
        return Promise.reject(error)
    }
)

export { service }