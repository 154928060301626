import { ref, reactive } from 'vue';
import { ak } from '../utils'
import { MapMarkerOptions, MapOptions } from './types';
import { getLoadScriptObject, loadScriptConfig } from '../utils/useMapLibraries';

export function useMap() {
    const bMap = ref<any>();
    const mapIns = ref<any>();
    const isInit = ref<boolean>(false);
    const markerMap = reactive<Record<string, any>>({});
    const isWebGL = false;

    const loadBMap = () => {
        return new Promise((resolve, reject) => {
            const { BMapGL, BMap } = window;
            const mapPrototype = isWebGL ? BMapGL : BMap;
            const mapPrototypeIndex = isWebGL ? 'BMapGL' : 'BMap';
            const mapType = isWebGL ? 'type=webgl&' : '';

            if (mapPrototype) {
                console.log('地图已加载')
                resolve(mapPrototype);
            } else {
                const BMap_URL = `https://api.map.baidu.com/api?${mapType}v=3.0&ak=${ak}&s=1&callback=onBMapCallback`;
                const scriptNode = document.createElement("script");
                scriptNode.setAttribute("type", "text/javascript");
                scriptNode.setAttribute("src", BMap_URL);
                document.body.appendChild(scriptNode);
            }
            window.onBMapCallback = function () {
                console.log('地图加载成功')
                resolve(window[mapPrototypeIndex]);
            };
        });
    }

    const getLoadScriptPromise = (url: string) => {
        return new Promise<void>((resolve, reject) => {
            const regex = /\.css$/i; // 匹配以 .css 结尾的字符串
            const isCss = regex.test(url);
            if (isCss) {
                try {
                    const link = document.createElement("link");
                    link.rel = 'stylesheet';
                    link.href = url;
                    document.head.appendChild(link);
                    link.onload = () => {
                        resolve()
                    };
                    link.onerror = (error) => { 
                        reject('Load css failed, reason: ' + error)
                    };
                } catch (error) {
                    reject(error);
                    console.error(error);
                }
            } else {
                try {
                    const scriptNode = document.createElement("script");
                    scriptNode.setAttribute("type", "text/javascript");
                    scriptNode.setAttribute("src", url);
                    document.body.appendChild(scriptNode);
                    scriptNode.onload = () => {
                        resolve()
                    };
                    scriptNode.onerror = (error) => { 
                        reject('Load script failed, reason: ' + error)
                    };
                } catch (error) {
                    reject(error);
                    console.error(error);
                }
            }
        })
    }

    /**
     * @typedef {Object} loadScriptConfig
     * @property {string} script - 模块名
     * @property {string} urls[0] - 脚本地址
     * @property {string} urls[1] - CSS地址
     * 使用方法getLoadScriptObject可以直接输入模块名动态加载
     * warning: 该方法载入的地址套用模板，如果加载地址错误请直接调用loadBMapLib方法
     * exmaple: const drawingManager = await loadBMapLib(getLoadScriptObject('drawingmanager'));
     * @param {string} scriptName - 模块名
     */
    const loadBMapLib = ({ script, urls }: loadScriptConfig) => {
        const { BMap, BMapGL, BMapLib, BMapGLLib } = window;
        const bMap = isWebGL ? BMapGL : BMap;
        const bMapLib = isWebGL ? BMapGLLib : BMapLib;
        return new Promise<any>((resolve, reject) => {
            if (!bMap) {
                reject(new Error('加载失败，请确保已经载入百度地图'));
            } else if (!bMapLib || !bMapLib[script]) {
                const promiseList: Promise<void>[] = [];
                for (let i = 0; i < urls.length; i++) {
                    promiseList.push(getLoadScriptPromise(urls[i]))
                }
                Promise.all(promiseList).then(() => {
                    const lib = isWebGL ? window.BMapGLLib : window.BMapLib;
                    console.log(lib, lib[script], script)
                    if (!lib || !lib[script]) {
                        reject('加载失败，请确认模块名或者模块参数正确')
                    } else {
                        console.log(`Load ${script} successed.`)
                        resolve(lib[script]);
                    }
                }).catch((error) => reject(`加载失败，加载途中发生错误:${error}`));
            } else if (bMapLib[script]) {
                console.log(`${script} was loaded.`)
                resolve(bMapLib[script]);
            }
        });
    };

    const useMapLibrary = (script) => {
        return loadBMapLib(getLoadScriptObject(script))
    }

    const initMap = async (El: string, options?): Promise<{ map: any, bMap: any }> => {
        const BMap: any = await loadBMap();
        return new Promise((resolve, reject) => {
            bMap.value = BMap;
            mapIns.value = new bMap.value.Map(El, options);
            // mapIns.value.centerAndZoom('西安', 6);
            mapIns.value.enableScrollWheelZoom(true);
            mapIns.value.disableDoubleClickZoom();
            isInit.value = true;
            resolve({
                map: mapIns.value,
                bMap: bMap.value,
            });
        })
        //markerClusterer.value = new value.BMapLib.MarkerClusterer(mapIns.value, { markers: [] });
    }

    const getMap = () => {
        return isInit.value ? mapIns.value : null
    }

    const setMap = (params) => {
        bMap.value = params.bMap;
        mapIns.value = params.map;
        if (bMap.value && mapIns.value) {
            isInit.value = true;
        }
    }

    const getBMap = async () => {
        const BMap: any = await loadBMap();
        return new Promise((resolve, reject) => {
            resolve(BMap);
        })
    }

    const addMarker = (options: MapMarkerOptions, mapOption?: MapOptions) => {
        const markerMapIns = mapOption?.map || mapIns.value;
        const markerBMap = mapOption?.bMap || bMap.value;
        const { lat, lng, markerName, id, rotation, iconSrc, iconOffset, iconImageSize, iconViewSize, isHidden, isRender=true, zIndex = 1000 } = options;
        const size = iconViewSize ? new markerBMap.Size(iconViewSize.width, iconViewSize.height) : new markerBMap.Size(30, 30)
        const icon = new markerBMap.Icon(iconSrc, size);
        iconImageSize && icon.setImageSize(new markerBMap.Size(iconImageSize.width, iconImageSize.height));
        iconOffset && icon.setAnchor(new markerBMap.Size(iconOffset.width, iconOffset.height));
        const point = new markerBMap.Point(Number(lng), Number(lat));
        const marker = new markerBMap.Marker(point, { 
            icon: iconSrc && icon, 
            rotation 
        });
        if (isRender) markerMapIns.addOverlay(marker);
        if (isHidden) marker.hide();
        const key = markerName || id;
        marker.setZIndex(zIndex)
        markerMap[key] = ref(marker);
        return markerMap[key];
    }

    const getMarker = (markerName) => {
        return markerMap[markerName] || null
    }

    const hideMarker = (value: string | Record<string, any>) => {
        if (typeof value === 'string') {
            const marker = markerMap[value];
            if (!marker) {
                console.warn(`找不到名为${value}的标记点。`);
            } else {
                marker.hide();
            }
        } else {
            value.hide();
        }
    }

    const showMarker = (value: string | Record<string, any>) => {
        if (typeof value === 'string') {
            const marker = markerMap[value];
            if (!marker) {
                console.warn(`找不到名为${value}的标记点。`);
            } else {
                marker.show();
            }
        } else {
            value.show();
        }
    }

    const removeMarker = (options) => {
        const { markerName, map } = options;
        const marker = markerMap[markerName];
        if (!marker) {
            console.warn(`找不到名为${markerName}的标记点。`);
        } else {
            (map || mapIns.value).removeOverlay(marker);
            markerMap[markerName] = null;
        }
    }

    const getBoundItem = (markerName) => {
        const marker = markerMap[markerName] || null
        return marker && marker.boundItem
    }

    const setViewport = (points) => {
        if (!points || points.length === 0) return
        if (points.length === 1 || !Array.isArray(points)) {
            const point = Array.isArray(points) ? points[0] : points;
            mapIns.value.setViewport({
                center: new bMap.value.Point(Number(point.lng), Number(point.lat)),
                zoom: mapIns.value.getZoom()
            })
        } else {
            mapIns.value.setViewport(points.map(point => new bMap.value.Point(Number(point.lng), Number(point.lat))));
        }
    }

    return {
        initMap,
        getMap,
        setMap,
        getBMap,
        getMarker,
        addMarker,
        // stopMarker,
        setViewport,
        loadBMapLib,
        useMapLibrary,
        hideMarker,
        showMarker,
        removeMarker
    }
}

